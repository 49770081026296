<template>
  <Layout>
    <div class="container pt-5">
      <div class="row justify-content-center pt-5">
        <div class="col col-md-10 col-lg-8 col-xl-6">
          <div class="text-center mb-5">
            <img :src="outcommsLogo" alt="outcomms" style="max-height: 80px" />
          </div>
          <p class="h3 pb-4 text-center text-success">
            {{ formProperties.title }}
          </p>

          <div v-if="isSuccess">
            <div>
              <span>Sign Up Successfull! Logging in...</span>
              <BaseIcon name="sync" spin />
            </div>
          </div>
          <div v-if="authError && !showForgetPassword">
            <div>
              {{ authError }}
            </div>
          </div>

          <form class="px-5" @submit.prevent="formProperties.action">
            <div class="mb-3">
              <BaseInput v-model="username" name="username" placeholder="Email" />
            </div>
            <div class="mb-3">
              <BaseInput v-model="password" name="password" type="password" placeholder="Password" />
            </div>

            <div class="mt-2 mb-1 text-center">
              <BaseButton :disabled="inProgress" type="submit" classes="me-2 btn-success">
                <BaseIcon v-if="inProgress && !isSuccess" name="sync" spin />
                <span v-else>
                  {{ formProperties.buttonText }}
                </span>
              </BaseButton>
              <!-- <BaseButton
                :disabled="inProgress"
                type="button"
                classes="btn-secondary me-2"
                @click.prevent="socialLogin"
              >
                <BaseIcon v-if="inProgress && !isSuccess" :name="['fas', 'sync']" spin />
                <span v-else> Log in with SSO </span>
              </BaseButton> -->
              <BaseButton :disabled="inProgress" type="button" class="me-2 btn-info" @click.prevent="oktaLogin">
                <BaseIcon v-if="inProgress && !isSuccess" :name="['fas', 'sync']" spin />
                <span v-else> Log in with Okta </span>
              </BaseButton>
            </div>
            <div class="row my-3 justify-content-around">
              <div :v-if="formProperties.leftLinkText" class="col text-center">
                <a class="form-text btn btn-sm btn-light" @click="formProperties.leftAction">
                  {{ formProperties.leftLinkText }}
                </a>
              </div>
              <!-- <div :v-if="formProperties.rightLinkText" class="col text-center">
                <a class="form-text btn btn-sm btn-light" @click="formProperties.rightAction">
                  {{ formProperties.rightLinkText }}
                </a>
              </div> -->
            </div>
          </form>
        </div>

        <div v-if="showForgetPassword">
          <p v-if="!isSuccessForgetPassword">Forgot yout password?</p>
          <p v-if="isSuccessForgetPassword">
            <BaseIcon name="plane" />
            <span>We've just sent you an email to reset your password</span>
          </p>
          <div v-if="inProgressForgetPassword">
            <div>
              <span>Sending email...</span>
              <BaseIcon name="sync" spin />
            </div>
          </div>
          <div v-if="authError && showForgetPassword">
            <div>
              {{ authError }}
            </div>
          </div>
          <form v-if="!isSuccessForgetPassword" @submit.prevent="resetPassword">
            <BaseInput v-model="resetEmail" name="resetEmail" placeholder="Email" type="email" />
            <BaseButton :disabled="inProgressForgetPassword" type="submit">
              <span>Reset My Password</span>
            </BaseButton>
            <BaseButton class="btn btn-secondary btn-sm d-blok mx-auto mt-4" @click="showForgetPassword = false">
              <span>Back</span>
            </BaseButton>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@layouts/bare";
import appConfig from "@src/app.config";
import { authorizeSelf, signupSelf, passwordReset, authorizeSocial } from "@services/auth";

import outcommsLogo from "@assets/images/outcommslogo.png";

export default {
  page: {
    title: "Log in",
    meta: [{ name: "description", content: `Log in to ${appConfig.title}` }],
  },
  components: { Layout },
  data() {
    return {
      username: "",
      password: "",
      resetEmail: "",
      authError: undefined,
      isSuccess: false,
      inProgress: false,
      formProperties: {},
      showForgetPassword: false,
      inProgressForgetPassword: false,
      isSuccessForgetPassword: false,
      outcommsLogo: outcommsLogo
    };
  },
  created() {
    this.showLogInForm();
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    async logIn() {
      this.clearErrors();
      this.showForgetPassword = false;
      this.inProgress = true;

      try {
        // This redirects to home page
        await authorizeSelf(this.username, this.password);
      } catch (err) {
        this.setError(err);
      }

      this.inProgress = false;
    },
    // Try to create a new account for the user
    // with the username and password they provided.
    async signUp() {
      this.clearErrors();
      this.inProgress = true;

      // This redirects to home page
      try {
        await signupSelf(this.username, this.password);
        this.isSuccess = true;
        this.logIn();
      } catch (err) {
        this.setError(err);
      }

      this.inProgress = false;
    },

    async socialLogin() {
      await authorizeSocial("google");
    },

    async oktaLogin() {
      await authorizeSocial("devOKTA");
    },

    async resetPassword() {
      this.clearErrors();
      this.inProgressForgetPassword = true;

      // This redirects to home page
      try {
        await passwordReset(this.resetEmail);
        this.isSuccessForgetPassword = true;
      } catch (err) {
        this.setError(err);
      }
      this.inProgressForgetPassword = false;
    },

    showSignUpForm() {
      this.formProperties = {
        title: "Create a new account",
        buttonText: "Sign up",
        leftLinkText: "",
        leftAction: () => {},
        rightLinkText: "Already have an account",
        rightAction: this.showLogInForm,
        action: this.signUp,
      };
      this.clearErrors();
      this.clearContent();
    },

    showLogInForm() {
      this.formProperties = {
        title: "Login to access your account",
        buttonText: "Log in",
        leftLinkText: "Forgot your password?",
        leftAction: this.showPasswordResetForm,
        rightLinkText: undefined,
        rightAction: undefined,
        action: this.logIn,
      };
      this.clearErrors();
      this.clearContent();
    },

    showPasswordResetForm() {
      this.clearErrors();
      this.clearContent();
      this.showForgetPassword = true;
    },

    clearErrors() {
      // Reset the authError if it existed.
      this.authError = undefined;
    },

    clearContent() {
      this.username = "";
      this.password = "";
      this.resetEmail = "";
      this.showForgetPassword = false;
      this.isSuccessForgetPassword = false;
    },

    setError(error) {
      if (typeof error.description === "string") {
        this.authError = error.description;
      } else {
        this.authError = error.policy;
      }
    },
  },
};
</script>
